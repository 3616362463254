import { createFileRoute } from '@tanstack/react-router';
import SyntaxHighlighter from 'react-syntax-highlighter';

export const Route = createFileRoute('/docs/webhooks/')({
  component: webhooksDocs,
});

function webhooksDocs() {
  return (
    <div className="p-8">
      <h1 className="text-4xl font-extrabold dark:text-white">Webhooks</h1>
      <p className="mb-3 text-gray-500 dark:text-gray-400">You can make your own integration for browz using the 'webhooks' integration. If you do this, you'll have to
        create a server that adheres to the <a
          href="/docs/webhooks/api"
          className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
        >api
        </a></p>
      <h2 className="text-3xl font-extrabold dark:text-white">Example</h2>
      <p className="mb-3 text-gray-500 dark:text-gray-400">Here's an example typescript server</p>
      <SyntaxHighlighter language="typescript">
        {`import express, { Request, Response } from 'express';
import bodyParser from 'body-parser';

const app = express();
const port = 3000;

app.use(bodyParser.json());

// Define routes based on the OpenAPI schema

// Create Placeholder
app.post('/createPlaceholder', (req: Request, res: Response) => {
  // Logic to create a placeholder
  res.status(200).json(req.body);
});

// Get Media by ID
app.get('/media/:id', (req: Request, res: Response) => {
  // Logic to get media by ID
  res.status(200).json({ mediaId: req.params.id });
});

// Get Thumbnail URL
app.get('/media/:id/thumbnail', (req: Request, res: Response) => {
  // Logic to get thumbnail URL
  res.status(200).json({ url: \`https://example.com/media/\${ req.params.id }/thumbnail\` });
});

// Get Items
app.get('/items', (req: Request, res: Response) => {
  // Logic to get items
  res.status(200).json([{ id: 'bbef5886-dbdc-4406-af58-86dbdc140682' }, { id: '46842429-7449-49c5-8424-297449a9c5ae' }]);
});

// Get Proxy URL
app.get('/proxy/:id', (req: Request, res: Response) => {
  // Logic to get proxy URL
  res.status(200).json({ url: \`https://example.com/proxy/\${ req.params.id }\` });
});

// Search Media
app.get('/search', (req: Request, res: Response) => {
  // Logic to search media
  res.status(200).json([{ id: '16c99e00-011d-4835-899e-00011d58354e'}]);
});

app.listen(port, () => {
  console.log(\`Server is running on http://localhost:\${ port }\`);
});`}
      </SyntaxHighlighter>
    </div>
  );
}
