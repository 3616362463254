/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as IframeIndexImport } from './routes/iframe/index'
import { Route as DocsIndexImport } from './routes/docs/index'
import { Route as BrowzrIndexImport } from './routes/browzr/index'
import { Route as PlaybackMediaIdImport } from './routes/playback/$mediaId'
import { Route as DocsWebhooksIndexImport } from './routes/docs/webhooks/index'
import { Route as DocsStandardizedMediaInfoIndexImport } from './routes/docs/standardizedMediaInfo/index'
import { Route as DocsWebhooksApiImport } from './routes/docs/webhooks/api'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()

// Create/Update Routes

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const IframeIndexRoute = IframeIndexImport.update({
  id: '/iframe/',
  path: '/iframe/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/iframe/index.lazy').then((d) => d.Route))

const DocsIndexRoute = DocsIndexImport.update({
  id: '/docs/',
  path: '/docs/',
  getParentRoute: () => rootRoute,
} as any)

const BrowzrIndexRoute = BrowzrIndexImport.update({
  id: '/browzr/',
  path: '/browzr/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/browzr/index.lazy').then((d) => d.Route))

const PlaybackMediaIdRoute = PlaybackMediaIdImport.update({
  id: '/playback/$mediaId',
  path: '/playback/$mediaId',
  getParentRoute: () => rootRoute,
} as any)

const DocsWebhooksIndexRoute = DocsWebhooksIndexImport.update({
  id: '/docs/webhooks/',
  path: '/docs/webhooks/',
  getParentRoute: () => rootRoute,
} as any)

const DocsStandardizedMediaInfoIndexRoute =
  DocsStandardizedMediaInfoIndexImport.update({
    id: '/docs/standardizedMediaInfo/',
    path: '/docs/standardizedMediaInfo/',
    getParentRoute: () => rootRoute,
  } as any)

const DocsWebhooksApiRoute = DocsWebhooksApiImport.update({
  id: '/docs/webhooks/api',
  path: '/docs/webhooks/api',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/playback/$mediaId': {
      id: '/playback/$mediaId'
      path: '/playback/$mediaId'
      fullPath: '/playback/$mediaId'
      preLoaderRoute: typeof PlaybackMediaIdImport
      parentRoute: typeof rootRoute
    }
    '/browzr/': {
      id: '/browzr/'
      path: '/browzr'
      fullPath: '/browzr'
      preLoaderRoute: typeof BrowzrIndexImport
      parentRoute: typeof rootRoute
    }
    '/docs/': {
      id: '/docs/'
      path: '/docs'
      fullPath: '/docs'
      preLoaderRoute: typeof DocsIndexImport
      parentRoute: typeof rootRoute
    }
    '/iframe/': {
      id: '/iframe/'
      path: '/iframe'
      fullPath: '/iframe'
      preLoaderRoute: typeof IframeIndexImport
      parentRoute: typeof rootRoute
    }
    '/docs/webhooks/api': {
      id: '/docs/webhooks/api'
      path: '/docs/webhooks/api'
      fullPath: '/docs/webhooks/api'
      preLoaderRoute: typeof DocsWebhooksApiImport
      parentRoute: typeof rootRoute
    }
    '/docs/standardizedMediaInfo/': {
      id: '/docs/standardizedMediaInfo/'
      path: '/docs/standardizedMediaInfo'
      fullPath: '/docs/standardizedMediaInfo'
      preLoaderRoute: typeof DocsStandardizedMediaInfoIndexImport
      parentRoute: typeof rootRoute
    }
    '/docs/webhooks/': {
      id: '/docs/webhooks/'
      path: '/docs/webhooks'
      fullPath: '/docs/webhooks'
      preLoaderRoute: typeof DocsWebhooksIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/playback/$mediaId': typeof PlaybackMediaIdRoute
  '/browzr': typeof BrowzrIndexRoute
  '/docs': typeof DocsIndexRoute
  '/iframe': typeof IframeIndexRoute
  '/docs/webhooks/api': typeof DocsWebhooksApiRoute
  '/docs/standardizedMediaInfo': typeof DocsStandardizedMediaInfoIndexRoute
  '/docs/webhooks': typeof DocsWebhooksIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/playback/$mediaId': typeof PlaybackMediaIdRoute
  '/browzr': typeof BrowzrIndexRoute
  '/docs': typeof DocsIndexRoute
  '/iframe': typeof IframeIndexRoute
  '/docs/webhooks/api': typeof DocsWebhooksApiRoute
  '/docs/standardizedMediaInfo': typeof DocsStandardizedMediaInfoIndexRoute
  '/docs/webhooks': typeof DocsWebhooksIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/playback/$mediaId': typeof PlaybackMediaIdRoute
  '/browzr/': typeof BrowzrIndexRoute
  '/docs/': typeof DocsIndexRoute
  '/iframe/': typeof IframeIndexRoute
  '/docs/webhooks/api': typeof DocsWebhooksApiRoute
  '/docs/standardizedMediaInfo/': typeof DocsStandardizedMediaInfoIndexRoute
  '/docs/webhooks/': typeof DocsWebhooksIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/playback/$mediaId'
    | '/browzr'
    | '/docs'
    | '/iframe'
    | '/docs/webhooks/api'
    | '/docs/standardizedMediaInfo'
    | '/docs/webhooks'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/playback/$mediaId'
    | '/browzr'
    | '/docs'
    | '/iframe'
    | '/docs/webhooks/api'
    | '/docs/standardizedMediaInfo'
    | '/docs/webhooks'
  id:
    | '__root__'
    | '/'
    | '/playback/$mediaId'
    | '/browzr/'
    | '/docs/'
    | '/iframe/'
    | '/docs/webhooks/api'
    | '/docs/standardizedMediaInfo/'
    | '/docs/webhooks/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  PlaybackMediaIdRoute: typeof PlaybackMediaIdRoute
  BrowzrIndexRoute: typeof BrowzrIndexRoute
  DocsIndexRoute: typeof DocsIndexRoute
  IframeIndexRoute: typeof IframeIndexRoute
  DocsWebhooksApiRoute: typeof DocsWebhooksApiRoute
  DocsStandardizedMediaInfoIndexRoute: typeof DocsStandardizedMediaInfoIndexRoute
  DocsWebhooksIndexRoute: typeof DocsWebhooksIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  PlaybackMediaIdRoute: PlaybackMediaIdRoute,
  BrowzrIndexRoute: BrowzrIndexRoute,
  DocsIndexRoute: DocsIndexRoute,
  IframeIndexRoute: IframeIndexRoute,
  DocsWebhooksApiRoute: DocsWebhooksApiRoute,
  DocsStandardizedMediaInfoIndexRoute: DocsStandardizedMediaInfoIndexRoute,
  DocsWebhooksIndexRoute: DocsWebhooksIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/playback/$mediaId",
        "/browzr/",
        "/docs/",
        "/iframe/",
        "/docs/webhooks/api",
        "/docs/standardizedMediaInfo/",
        "/docs/webhooks/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/playback/$mediaId": {
      "filePath": "playback/$mediaId.tsx"
    },
    "/browzr/": {
      "filePath": "browzr/index.tsx"
    },
    "/docs/": {
      "filePath": "docs/index.tsx"
    },
    "/iframe/": {
      "filePath": "iframe/index.tsx"
    },
    "/docs/webhooks/api": {
      "filePath": "docs/webhooks/api.tsx"
    },
    "/docs/standardizedMediaInfo/": {
      "filePath": "docs/standardizedMediaInfo/index.tsx"
    },
    "/docs/webhooks/": {
      "filePath": "docs/webhooks/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
