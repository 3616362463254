import { createRootRoute, Outlet } from '@tanstack/react-router';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient();

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      })),
    );


export const Route = createRootRoute({
  component: () => (
    <>
      <QueryClientProvider client={ queryClient }>
        <Outlet />
        <Suspense>
          <ReactQueryDevtools initialIsOpen={false} />
          <TanStackRouterDevtools />
        </Suspense>
      </QueryClientProvider>
    </>
  ),
});
