import { createFileRoute } from '@tanstack/react-router';
import SyntaxHighlighter from 'react-syntax-highlighter';

export const Route = createFileRoute('/docs/standardizedMediaInfo/')({
  component: StandardizedMediaInfoDocs,
})

function StandardizedMediaInfoDocs() {
  return (
    <div className="p-8">
      <h1 className="text-4xl font-extrabold dark:text-white">Standardized Media Info</h1>
      <p className="mb-3 text-gray-500 dark:text-gray-400">This is how we communicate media info throughout our applications</p>
      <SyntaxHighlighter language="typescript">
        {`
import { BrowzMimeType } from './MimeType.type';

export type MediaType = 'video' | 'audio' | 'image';

export type MediaStatus = 'placeholder' | 'available' | 'unavailable' | 'transcoding' | 'error' | 'complete' | 'unknown';

export interface StandardisedMediaInfo {
  createdAt?: Date;
  createdBy?: string;
  dimensions?: {
    height: number;
    width: number;
  };
  durationInMilliseconds?: number;
  hasProxy?: boolean;
  highRes?: string;
  isDeleting?: boolean;
  mediaId: string;
  originalFileName?: string;
  path?: string;
  proxy?: string;
  sizeInBytes?: number;
  status: MediaStatus;
  technicalMetadata?: Record<string, unknown>;
  thumbnail?: string;
  title?: string;
  type: MediaType;
  mimeType?: BrowzMimeType;
}
         `}
      </SyntaxHighlighter>
    </div>
  )
}
