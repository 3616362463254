import { createFileRoute } from '@tanstack/react-router';
import SwaggerUI from 'swagger-ui-react';
import api from './Webhooks.json';
import 'swagger-ui-react/swagger-ui.css';

export const Route = createFileRoute('/docs/webhooks/api')({
  component: RouteComponent,
});

function RouteComponent() {
  return <SwaggerUI spec={api} />;
}