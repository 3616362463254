import { useQuery } from '@tanstack/react-query';
import getProxyUrl from '@/lib/getProxy.ts';
import { StandardisedMediaInfo } from '@repo/types/StandardisedMediaInfo.type.ts';

interface Props {
  token: string;
  mediaId: string;
  item: StandardisedMediaInfo;
}

export default function Player({ token, mediaId, item }: Props) {
  const { isPending, isError, data: src, error } = useQuery({
    queryKey: [ 'proxyUrl', token, mediaId ],
    queryFn: () => getProxyUrl(token, mediaId),
  });

  if (isPending) return <div>Loading...</div>;
  if (isError) return <div>Error: { error.message }</div>;

  if(item.type === 'video') {
    return (
      <video controls>
        <source src={ src } />
      </video>
    )
  }

  if(item.type === 'image') {
    return (
      <img src={ src } alt={ item.title } />
    )
  }
}