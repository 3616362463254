
import { useQuery } from '@tanstack/react-query';
import getManifest from '@/lib/getManifest.ts';

interface Props {
  token: string;
  mediaId: string;
}

export default function EmbeddedPlayer({ token, mediaId }: Props) {
  const { isPending, isError, data: manifest, error } = useQuery({
    queryKey: [ 'manifest', token ],
    queryFn: () => getManifest(token),
  });

  if (isPending) return <div>Loading...</div>;
  if (isError) return <div>Error: { error.message }</div>;

  console.log(manifest);

  return (
    <iframe src={ manifest.mediaLink.replace(':mediaId', mediaId) } className="w-full h-full" />
  )
}