import { createFileRoute } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import useToken from '@/hooks/useToken.ts';
import Player from '@/components/Player';
import getMediaById from '@/lib/getMediaById.ts';
import EmbeddedPlayer from '@/components/EmbeddedPlayer';
import { StandardisedMediaInfo } from '@repo/types/StandardisedMediaInfo.type.ts';

interface Query {
  token: string;
}

export const Route = createFileRoute('/playback/$mediaId')({
  validateSearch: (search: Record<string, unknown>): Query => {
    return {
      token: (search.token as string) || '',
    };
  },
  component: PlayBack,
});

function PlayBack() {
  const { token } = Route.useSearch();
  const { mediaId } = Route.useParams();
  const {integrationId} = useToken(token);
  const { isPending, isError, data: item, error } = useQuery({
    queryKey: ['item', token, mediaId],
    queryFn: () => getMediaById(token, mediaId),
  })

  if (isPending) return <div>Loading...</div>;
  if (isError) return <div>Error: { error.message }</div>;

  if(integrationId === 'mimir') {
    return <EmbeddedPlayer token={token} mediaId={ mediaId } />;
  }

  return <Player token={token} mediaId={mediaId} item={item as StandardisedMediaInfo} />;
}