import './index.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import * as Sentry from '@sentry/react';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Create a new router instance
const router = createRouter({ routeTree });

Sentry.init({
  dsn: 'https://9f87aec634f55b76df22acc12fe16dde@o4507430741082112.ingest.de.sentry.io/4508319417172048',
  integrations: [ Sentry.tanstackRouterBrowserTracingIntegration(router) ],

  // Setting a sample rate is required for sending performance data.
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control.
  tracesSampleRate: 1.0,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Sentry.ErrorBoundary fallback={ <p>An error has occurred</p> }>
      <StrictMode>
        <RouterProvider router={ router } />
      </StrictMode>
    </Sentry.ErrorBoundary>,
  );
}
