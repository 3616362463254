import { createFileRoute } from '@tanstack/react-router';

interface Query {
  token: string;
}

export const Route = createFileRoute('/browzr/')({
  validateSearch: (search: Record<string, unknown>): Query => {
    return {
      token: (search.token as string) || '',
    }
  }
})